






































import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import { ProfileFilter } from 'client-website-ts-library/filters';
import { Profile, WebsiteLevel } from 'client-website-ts-library/types';
import ProfileCards from '@/components/ProfileCards.vue';
import Loader from '@/components/UI/Loader.vue';

import NavigationLink from '../components/Common/NavigationLink.vue';
import Button from '../components/UI/Button.vue';

@Component({
  components: {
    NavigationLink,
    Button,
    ProfileCards,
    Loader,
  },
})
export default class About extends Mixins(View) {
    private profiles: Profile[] = [];

    mounted() {
      // const filter = new ProfileFilter();

      // API.Profiles.Search(filter).then((profiles) => {
      //   this.profiles = profiles;
      //   console.log('Profiles');
      //   console.log(this.profiles);
      // });

      API.Profiles.Search(
        new ProfileFilter({
          SearchLevel: WebsiteLevel.Office,
          SearchGuid: Config.Website.Settings!.WebsiteId,
        }),
        true,
      ).then((profiles) => {
        this.profiles = profiles;
        console.log('profiles');
        console.log(this.profiles);
      });
    }
}
